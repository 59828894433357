import type { ReturnDetailsResult } from '@api/routes/rooms'
import type { Routes } from '@api/server'
import { hc } from 'hono/client'
import { useRouteData } from 'solid-start'
import { createServerData$ } from 'solid-start/server'
import { Main, Meta, Title } from '~/components/html'
import {
  AddressWithTypes,
  Cover,
  Features,
  GradesWithCourses,
  Name,
  NavigationBar,
  SocialBar,
  StaticMaps,
  Stations,
  Summary,
  Toolbar
} from '~/components/routes/rooms'
import { Async, VerticalSlider, VerticalSliderSlide, VirtualLayout, VirtualScrollbar } from '~/components/ui'
import {
  FavoriteContextProvider,
  RequestedContextProvider,
  RoomContextProvider,
  VirtualContextProvider,
  useRoomContext
} from '~/contexts'
import { api } from '~/entry-api'
import { css, shape } from '~/libs'

type Payload = Parameters<ReturnType<typeof api>['rooms']['v1']['details']['$post']>[0]['json']
type ResolvedData = Awaited<Promise<ReturnDetailsResult>>
type MediatorProps = { data: ResolvedData }
type PresenterProps = MediatorProps

const { VITE_API_INTERNAL_HOST, VITE_APP_DOMAIN } = import.meta.env

export function routeData({ params }: RouteDataArgs) {
  const data = createServerData$(id => {
    if (isNaN(Number(id))) throw new Error('id must be number')
    const api = hc<Routes>(VITE_API_INTERNAL_HOST) // cannot use entry-api in the createServerData$ function
    const payload: Payload = { id: Number(id) }
    const res = api.rooms.v1.details.$post({ json: payload }).then(res => res.json())
    return res
  }, { key: () => params.id }) as Resource<ResolvedData>
  return { data }
}

export default function Page() {
  const { data } = useRouteData<typeof routeData>()
  const styled = {
    root: css({ WebkitFontSmoothing: 'antialiased' })
  }
  return (
    <>
      <Main class={styled.root}>
        <VirtualContextProvider>
          <VirtualLayout>
            <RoomContextProvider>
              <RequestedContextProvider>
                <FavoriteContextProvider>
                  <Async component={data => <Mediator data={data} />} data={data} />
                </FavoriteContextProvider>
              </RequestedContextProvider>
            </RoomContextProvider>
          </VirtualLayout>
          {/*<VirtualScrollbar />*/}
        </VirtualContextProvider>
      </Main>
    </>
  )
}

function Mediator({ data }: MediatorProps) {
  const ctx = useRoomContext()
  if (!ctx) throw new Error('ctx is not defined')
  ctx.setStore(JSON.parse(JSON.stringify(data)) as ResolvedData) // proxy to json
  return <Presenter data={data} />
}

function Presenter({ data }: PresenterProps) {
  const styled = {
    root: css({
      ...shape({ backgroundColor: '#fff' })
    })
  }
  const addrtypes = () => {
    const split = data.address.split(/(区|市)/)
    const addr = `${split[0]}${split[1]}、`
    const types = data.types.length >= 2 ? data.types.join('と').replace(/と(?=[^と]*$)/, 'の') : `${data.types}の`
    return `${addr}${types}`
  }
  return (
    <>
      <Title>{`${data.name} - ${addrtypes()}教室 - Concier109`}</Title>
      <Meta content={`${data.name} - ${addrtypes()}教室 - Concier109`} name='description' />
      <VerticalSlider class={styled.root}>
        <VerticalSliderSlide>
          <Toolbar />
          <Cover id={data.id} />
          <SocialBar />
        </VerticalSliderSlide>
        <Name />
        <AddressWithTypes />
        <GradesWithCourses />
        <Stations />
        <StaticMaps />
        <Features />
        <Summary />
      </VerticalSlider>
      <NavigationBar />
    </>
  )
}
