import { useLocation } from 'solid-start'

export function SearchEngineOptimize() {
  const { VITE_APP_URL } = import.meta.env
  const location = useLocation()
  return (
    <>
      <meta content='index, follow' name='robots' />
      <link href={`${VITE_APP_URL}${location.pathname}`} rel='canonical' />
      <link href='/sitemap.xml' rel='sitemap' />
      <link href='/favicon.ico' rel='icon' />
    </>
  )
}
