import * as goober from 'goober'

export { css } from 'goober'

export function keyframes(props: {
  from: goober.CSSAttribute
  to: goober.CSSAttribute
}) {
  const from = Object.entries(props.from).map(([key, value]) => `${key}: ${value};`).join(' ')
  const to = Object.entries(props.to).map(([key, value]) => `${key}: ${value};`).join(' ')
  return goober.keyframes(`from { ${from} } to { ${to} }`)
}

export function animation(props: {
  animation?: goober.CSSAttribute['animation']
  transform?: goober.CSSAttribute['transform']
  transformOrigin?: goober.CSSAttribute['transformOrigin']
  transition?: goober.CSSAttribute['transition']
  willChange?: goober.CSSAttribute['willChange']
}) {
  return { ...props }
}

export function ux(props: {
  cursor?: goober.CSSAttribute['cursor']
  pointerEvents?: goober.CSSAttribute['pointerEvents']
  userSelect?: goober.CSSAttribute['userSelect']
}) {
  return { ...props }
}

export function clamp(
  WebkitLineClamp: number
) {
  return {
    WebkitBoxOrient: 'vertical' as goober.CSSAttribute['WebkitBoxOrient'],
    WebkitLineClamp: WebkitLineClamp as goober.CSSAttribute['WebkitLineClamp'],
    display: '-webkit-box' as goober.CSSAttribute['display'],
    height: 'max-content' as goober.CSSAttribute['height'],
    overflowY: 'hidden' as goober.CSSAttribute['overflowY'],
    textOverflow: 'ellipsis' as goober.CSSAttribute['textOverflow']
  }
}

export function font(
  props: {
    color?: goober.CSSAttribute['color']
    fill?: goober.CSSAttribute['fill']
    fontFamily?: goober.CSSAttribute['fontFamily']
    fontSize?: goober.CSSAttribute['fontSize']
    fontWeight?: goober.CSSAttribute['fontWeight']
    letterSpacing?: goober.CSSAttribute['letterSpacing']
    lineHeight?: goober.CSSAttribute['lineHeight']
    textAlign?: goober.CSSAttribute['textAlign']
    textDecoration?: goober.CSSAttribute['textDecoration']
    textShadow?: goober.CSSAttribute['textShadow']
    verticalAlign?: goober.CSSAttribute['verticalAlign']
    whiteSpace?: goober.CSSAttribute['whiteSpace']
    wordBreak?: goober.CSSAttribute['wordBreak']
  }
) {
  return { ...props }
}

export function layout(props: {
  alignItems?: goober.CSSAttribute['alignItems']
  display?: goober.CSSAttribute['display']
  flex?: goober.CSSAttribute['flex']
  flexFlow?: goober.CSSAttribute['flexFlow']
  flexWrap?: goober.CSSAttribute['flexWrap']
  gridAutoColumns?: goober.CSSAttribute['gridAutoColumns']
  gridAutoFlow?: goober.CSSAttribute['gridAutoFlow']
  gridAutoRows?: goober.CSSAttribute['gridAutoRows']
  gridTemplateColumns?: goober.CSSAttribute['gridTemplateColumns']
  gridTemplateRows?: goober.CSSAttribute['gridTemplateRows']
  justifyContent?: goober.CSSAttribute['justifyContent']
  justifyItems?: goober.CSSAttribute['justifyItems']
  overflow?: goober.CSSAttribute['overflow']
  overflowX?: goober.CSSAttribute['overflowX']
  overflowY?: goober.CSSAttribute['overflowY']
  placeContent?: goober.CSSAttribute['placeContent']
  placeItems?: goober.CSSAttribute['placeItems']
  visibility?: goober.CSSAttribute['visibility']
}) {
  return { ...props }
}

export function position(
  props: {
    bottom?: goober.CSSAttribute['bottom']
    content?: goober.CSSAttribute['content']
    left?: goober.CSSAttribute['left']
    position?: goober.CSSAttribute['position']
    right?: goober.CSSAttribute['right']
    top?: goober.CSSAttribute['top']
    transform?: goober.CSSAttribute['transform']
    transformOrigin?: goober.CSSAttribute['transformOrigin']
    zIndex?: goober.CSSAttribute['zIndex']
  }
) {
  return { ...props }
}

export function scale(
  props: {
    aspectRatio?: goober.CSSAttribute['aspectRatio']
    objectFit?: goober.CSSAttribute['objectFit']
  }
) {
  return { ...props }
}

export function shape(
  props: {
    backgroundColor?: goober.CSSAttribute['backgroundColor']
    backgroundImage?: goober.CSSAttribute['backgroundImage']
    backgroundSize?: goober.CSSAttribute['backgroundSize']
    border?: goober.CSSAttribute['border']
    borderBottom?: goober.CSSAttribute['borderBottom']
    borderBottomLeftRadius?: goober.CSSAttribute['borderBottomLeftRadius']
    borderBottomRightRadius?: goober.CSSAttribute['borderBottomRightRadius']
    borderColor?: goober.CSSAttribute['borderColor']
    borderLeft?: goober.CSSAttribute['borderLeft']
    borderRadius?: goober.CSSAttribute['borderRadius']
    borderRight?: goober.CSSAttribute['borderRight']
    borderTop?: goober.CSSAttribute['borderTop']
    borderTopLeftRadius?: goober.CSSAttribute['borderTopLeftRadius']
    borderTopRightRadius?: goober.CSSAttribute['borderTopRightRadius']
    boxShadow?: goober.CSSAttribute['boxShadow']
    inset?: goober.CSSAttribute['inset']
    opacity?: goober.CSSAttribute['opacity']
    outline?: goober.CSSAttribute['outline']
    padding?: goober.CSSAttribute['padding']
    paddingBottom?: goober.CSSAttribute['paddingBottom']
    paddingLeft?: goober.CSSAttribute['paddingLeft']
    paddingTop?: goober.CSSAttribute['paddingTop']
  }
) {
  return { ...props }
}

export function size(
  props: {
    height?: goober.CSSAttribute['height']
    maxHeight?: goober.CSSAttribute['maxHeight']
    maxWidth?: goober.CSSAttribute['maxWidth']
    minHeight?: goober.CSSAttribute['minHeight']
    minWidth?: goober.CSSAttribute['minWidth']
    width?: goober.CSSAttribute['width']
  }
) {
  return { ...props }
}

export function space(
  props: {
    columnGap?: goober.CSSAttribute['columnGap']
    gap?: goober.CSSAttribute['gap']
    margin?: goober.CSSAttribute['margin']
    marginBlock?: goober.CSSAttribute['marginBlock']
    marginBlockEnd?: goober.CSSAttribute['marginBlockEnd']
    marginBlockStart?: goober.CSSAttribute['marginBlockStart']
    marginBottom?: goober.CSSAttribute['marginBottom']
    marginInline?: goober.CSSAttribute['marginInline']
    marginInlineEnd?: goober.CSSAttribute['marginInlineEnd']
    marginInlineStart?: goober.CSSAttribute['marginInlineStart']
    marginLeft?: goober.CSSAttribute['marginLeft']
    marginRight?: goober.CSSAttribute['marginRight']
    marginTop?: goober.CSSAttribute['marginTop']
    marginTrim?: goober.CSSAttribute['marginTrim']
    rowGap?: goober.CSSAttribute['rowGap']
  }
) {
  return { ...props }
}

export function scroll(
  props: {
    scrollPadding?: goober.CSSAttribute['scrollPadding']
    scrollSnapAlign?: goober.CSSAttribute['scrollSnapAlign']
    scrollSnapType?: goober.CSSAttribute['scrollSnapType']
  }
) {
  return { ...props }
}

export function counter(
  props: {
    counterIncrement?: goober.CSSAttribute['counterIncrement']
    counterReset?: goober.CSSAttribute['counterReset']
  }
) {
  return { ...props }
}
