import { splitProps } from 'solid-js'
import { Body as SolidBody } from 'solid-start'
import { css, font, size } from '~/libs'

type Props = ComponentProps<typeof SolidBody>

export function Body(props: Props) {
  const [{ children, class: root = '' }, attr] = splitProps(props, ['children', 'class'])
  const styled = {
    root: css({
      ...font({ fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif' }),
      ...size({ height: '100%' })
    })
  }
  const classes = [styled.root, root].filter(Boolean).join(' ')
  return <SolidBody class={classes} {...attr}>{children}</SolidBody>
}
