import { Suspense } from 'solid-js'
import { Assets, NoHydration } from 'solid-js/web'
import { ErrorBoundary, FileRoutes, Routes } from 'solid-start'
import { BasicMeta, OpenGraphProtocol, SearchEngineOptimize } from '~/components/head'
import { Body, Head, Html, Scripts } from '~/components/html'
import { Goober } from '~/components/libs'
import { IOSLikeCrossTransition } from '~/components/ui'
import { css, layout, size } from '~/libs'
import 'the-new-css-reset'

export default function Page() {
  const styled = { // for ios pull to refresh
    body: css({
      ...layout({ overflowY: 'auto' }),
      ...size({ height: '100%' }),
      '-webkit-overflow-scrolling': 'touch'
    }),
    html: css({
      ...layout({ overflow: 'hidden' }),
      [import.meta.env.VITE_MEDIA_DESKTOP_LARGE]: {
        fontSize: '69.44%'
      },
      [import.meta.env.VITE_MEDIA_DESKTOP_MEDIUM]: {
        fontSize: '0.6944vw'
      },
      [import.meta.env.VITE_MEDIA_SMARTPHONE]: {
        fontSize: '1.3333vw'
      }
    })
  }
  return (
    <Html class={styled.html} lang='ja'>
      <Head>
        <NoHydration>
          <BasicMeta />
          <SearchEngineOptimize />
          <OpenGraphProtocol />
        </NoHydration>
        <Assets>
          <Goober />
        </Assets>
        {
          /*
        <NoHydration>
          <Script
            async
            crossorigin='anonymous'
            src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1049023444437572'
          />
        </NoHydration>
  */
        }
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-05EVZX4XLT'></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-05EVZX4XLT');
        `}
        </script>
      </Head>
      <Body class={styled.body}>
        <IOSLikeCrossTransition>
          <Suspense>
            <ErrorBoundary>
              <Routes>
                <FileRoutes />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </IOSLikeCrossTransition>
        <Scripts />
      </Body>
    </Html>
  )
}
