import type { ReturnDetailsResult } from '@api/routes/rooms'
import type { Routes } from '@api/server'
import { formDataToObject } from 'formdata2json'
import { hc } from 'hono/client'
import { useLocation, useRouteData } from 'solid-start'
import { createServerAction$, createServerData$ } from 'solid-start/server'
import { Input, Main, Meta, Title } from '~/components/html'
import {
  AboutChild,
  AboutYou,
  Accuracy,
  NavigationBar,
  Room,
  ShippingAddress,
  Submit,
  TrialLesson
} from '~/components/routes/book/experiences'
import { Async, VerticalSlider, VerticalSliderSpacer, VirtualLayout, VirtualScrollbar } from '~/components/ui'
import {
  BookContextProvider,
  RequestedContextProvider,
  UserContextProvider,
  VirtualContextProvider,
  useBookContext
} from '~/contexts'
import { api } from '~/entry-api'
import { css, layout, shape, size } from '~/libs'

type DetailsPayload = Parameters<ReturnType<typeof api>['rooms']['v1']['details']['$post']>[0]['json']
type ExperiencesPayload = Parameters<ReturnType<typeof api>['book']['v1']['experiences']['$post']>[0]['json']
type ResolvedData = Awaited<Promise<ReturnDetailsResult>>
type MediatorProps = { data: ResolvedData }
type PresenterProps = MediatorProps & { Form: ParentComponent<FormData | FormProps> }

const { VITE_API_INTERNAL_HOST } = import.meta.env

export function routeData({ params }: RouteDataArgs) {
  const data = createServerData$(id => {
    if (isNaN(Number(id))) throw new Error('id must be number')
    const api = hc<Routes>(VITE_API_INTERNAL_HOST) // cannot use entry-api in the createServerData$ function
    const payload: DetailsPayload = { id: Number(id) }
    const res = api.rooms.v1.details.$post({ json: payload }).then(res => res.json())
    return res
  }, { key: () => params.id }) as Resource<ResolvedData>
  return { data }
}

export default function Page() {
  const { data } = useRouteData<typeof routeData>()
  const styled = {
    root: css({ WebkitFontSmoothing: 'antialiased' })
  }
  return (
    <>
      <Main class={styled.root}>
        <VirtualContextProvider>
          <VirtualLayout>
            <BookContextProvider>
              <RequestedContextProvider>
                <UserContextProvider>
                  <Async component={data => <Mediator data={data} />} data={data} />
                </UserContextProvider>
              </RequestedContextProvider>
            </BookContextProvider>
          </VirtualLayout>
          {/*<VirtualScrollbar />*/}
        </VirtualContextProvider>
      </Main>
    </>
  )
}

function Mediator({ data }: MediatorProps) {
  const ctx = useBookContext()
  if (!ctx) throw new Error('ctx is not defined')
  ctx.setStore(JSON.parse(JSON.stringify(data)) as ResolvedData) // proxy to json
  // <Form />
  const [request, { Form }] = createServerAction$((form: FormData) => {
    const payload = formDataToObject(form) as ExperiencesPayload
    payload.phone = String(form.get('phone')) // for bugfix overwrite
    payload.zipcode = String(form.get('zipcode')) //for bugfix overwrite
    const api = hc<Routes>(VITE_API_INTERNAL_HOST) // cannot use entry-api in the createServerData$ function
    const res = api.book.v1.experiences.$post({ json: payload }).then(res => res.json())
    console.log(res)
    return res
  })
  ctx.setRequest(request)
  return <Presenter data={data} Form={Form} />
}

function Presenter({ Form, data }: PresenterProps) {
  const styled = {
    form: css({
      ...layout({ display: 'contents' }),
      ...size({ width: '100%' })
    }),
    root: css({
      ...shape({ backgroundColor: '#ebebeb' })
    }),
    spacer: css({
      ...size({ height: 'calc(64px - 16px)' })
    })
  }
  const addrtypes = () => {
    const split = data.address.split(/(区|市)/)
    const addr = `${split[0]}${split[1]}、`
    const types = data.types.length >= 2 ? data.types.join('と').replace(/と(?=[^と]*$)/, 'の') : `${data.types}の`
    return `${addr}${types}`
  }
  return (
    <>
      <Title>{`${data.name} - ${addrtypes()}教室へ資料請求 - Concier109`}</Title>
      <Meta content={`${data.name} - ${addrtypes()}教室へ資料請求 - Concier109`} name='description' />
      <NavigationBar />
      <Form class={styled.form}>
        <VerticalSlider class={styled.root}>
          <Room />
          <Accuracy />
          <Input hidden name='id' value={data.id} />
          <Input hidden name='title' value={`${addrtypes()}教室 ${data.name}`} />
          <AboutChild />
          <AboutYou />
          <TrialLesson />
          <ShippingAddress />
          <Submit id={data.id} />
          <VerticalSliderSpacer class={styled.spacer} />
        </VerticalSlider>
      </Form>
    </>
  )
}
