import { createContextProvider } from '@solid-primitives/context'
import { createEffect, createMemo, createSignal, on } from 'solid-js'
import { isServer } from 'solid-js/web'
import { useServerContext } from 'solid-start'
import Cookies from 'universal-cookie'
import { ZodError, z } from 'zod'

type User = {
  address?: string
  childName?: string
  day?: string
  grade?: string
  mail?: string
  name?: string
  phone?: string
  relationship?: string
  trial?: string
  zipcode?: string
}

const UserSchema = z.object({
  address: z.string(),
  childName: z.string(),
  day: z.string(),
  grade: z.string(),
  mail: z.string().email(),
  name: z.string(),
  phone: z.string().refine(value => /^0\d{9,10}$/.test(value)),
  relationship: z.string(),
  trial: z.string(),
  zipcode: z.string().refine(value => /^[0-9]{7}$/.test(value))
}).strict()

const { VITE_APP_DOMAIN } = import.meta.env

const [UserContextProvider, useUserContext] = createContextProvider(() => {
  const event = useServerContext()
  const reader = new Cookies(isServer ? event.request.headers.get('cookie') : document)

  const expires = new Date()
  expires.setDate(expires.getDate() + 14)
  const writer = new Cookies(undefined, {
    domain: VITE_APP_DOMAIN,
    expires,
    path: '/',
    sameSite: 'strict',
    secure: true
  })

  const user = reader.get<User>('user')

  const [childName, setChildName] = createSignal(user?.childName)
  const [grade, setGrade] = createSignal(user?.grade)

  const [name, setName] = createSignal(user?.name)
  const [relationship, setRelationship] = createSignal(user?.relationship)
  const [mail, setMail] = createSignal(user?.mail)

  const [trial, setTrial] = createSignal(user?.trial)
  const [day, setDay] = createSignal(user?.day)

  const [zipcode, setZipcode] = createSignal(user?.zipcode)
  const [address, setAddress] = createSignal(user?.address)
  const [phone, setPhone] = createSignal(user?.phone)

  const [invalid, setInvalid] = createSignal<string>()

  const store = createMemo(() => {
    return {
      address: address(),
      childName: childName(),
      day: day(),
      grade: grade(),
      mail: mail(),
      name: name(),
      phone: phone(),
      relationship: relationship(),
      trial: trial(),
      zipcode: zipcode()
    }
  })

  createEffect(on(store, store => {
    writer.set('user', store)
  }, { defer: true }))

  const commit = (setter: Setter<string | undefined>, value: string) => {
    setter(value.length ? value : undefined)
  }

  const validate = () => {
    try {
      UserSchema.parse(store())
      setInvalid(undefined)
      return { index: 0 }
    } catch (error) {
      if (!(error instanceof ZodError)) return { index: 0 }
      const invalids = error.errors.map(({ path }) => path[0] as string)
      // 'childName', 'grade'
      if (invalids.includes('childName')) {
        setInvalid('childName')
        return { index: 2 }
      }
      if (invalids.includes('grade')) {
        setInvalid('grade')
        return { index: 2 }
      }
      // 'name', 'relationship', 'mail'
      if (invalids.includes('name')) {
        setInvalid('name')
        return { index: 3 }
      }
      if (invalids.includes('relationship')) {
        setInvalid('relationship')
        return { index: 3 }
      }
      if (invalids.includes('mail')) {
        setInvalid('mail')
        return { index: 3 }
      }
      // 'trial', 'day'
      if (invalids.includes('trial')) {
        setInvalid('trial')
        return { index: 4 }
      }
      if (invalids.includes('day')) {
        setInvalid('day')
        return { index: 4 }
      }
      // 'zipcode', 'address', 'phone'
      if (invalids.includes('zipcode')) {
        setInvalid('zipcode')
        return { index: 5 }
      }
      if (invalids.includes('address')) {
        setInvalid('address')
        return { index: 5 }
      }
      if (invalids.includes('phone')) {
        setInvalid('phone')
        return { index: 5 }
      }
      setInvalid(undefined)
      return { index: 0 }
    }
  }

  return {
    address,
    childName,
    commit,
    day,
    grade,
    invalid,
    mail,
    name,
    phone,
    relationship,
    setAddress,
    setChildName,
    setDay,
    setGrade,
    setInvalid,
    setMail,
    setName,
    setPhone,
    setRelationship,
    setTrial,
    setZipcode,
    trial,
    validate,
    zipcode
  }
})

export { UserContextProvider, useUserContext }
