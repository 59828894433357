import { createMemo, createSignal } from 'solid-js'
import { isServer } from 'solid-js/web'
import { useLocation, useServerContext } from 'solid-start'
import { Transition } from 'solid-transition-group'
import { UAParser } from 'ua-parser-js'
import { css, position } from '~/libs'

type Props = ComponentProps<'div'>

export function IOSLikeCrossTransition({ children }: Props) {
  const { VITE_VIRTUAL_LAYOUT_WIDTH } = import.meta.env

  const event = useServerContext()
  const parser = isServer ? new UAParser(event.request.headers.get('user-agent') ?? '') : new UAParser()
  const deviceType = parser.getResult().device.type
  const isMobile = createMemo(() => deviceType === 'mobile')

  const styled = {
    root: css({
      ...position({ bottom: 0, left: 0, position: 'fixed', right: 0, top: 0 })
    })
  }
  const transition = { // exit
    direction: {
      forward: css({ ...position({ zIndex: -1 }) }),
      reverse: css({ ...position({ zIndex: 1 }) }),
      stay: {
        forward: css({ ...position({ zIndex: -1 }) }),
        reverse: css({ ...position({ zIndex: 1 }) })
      }
    }
  }
  const P_25 = Number(VITE_VIRTUAL_LAYOUT_WIDTH.replace('px', '')) * 0.25
  const keyframe = {
    enter: {
      from: {
        in: [],
        left: [
          {
            filter: 'brightness(0.5)',
            transform: isMobile() ? 'translateX(-25%)' : `translateX(-${P_25}px)` /* 25% */
          },
          { transform: 'translateX(0)' }
        ],
        out: [
          { opacity: 0 },
          { opacity: 1 }
        ],
        right: [
          { transform: isMobile() ? 'translateX(100%)' : `translateX(${VITE_VIRTUAL_LAYOUT_WIDTH})` /* 100% */ },
          { transform: 'translateX(0)' }
        ]
      }
    },
    exit: {
      to: {
        in: [
          { opacity: 0 },
          { opacity: 1 }
        ],
        left: [
          { transform: 'translateX(0)' },
          { filter: 'brightness(0.5)', transform: isMobile() ? 'translateX(-25%)' : `translateX(-${P_25}px)` /* 25% */ }
        ],
        out: [],
        right: [
          { transform: 'translateX(0)' },
          { transform: isMobile() ? 'translateX(100%)' : `translateX(${VITE_VIRTUAL_LAYOUT_WIDTH})` /* 100% */ }
        ]
      }
    }
  }

  const options = { duration: 500, easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)' }

  const location = useLocation()

  const [hasExitElemet, setHasExitElemet] = createSignal(false)

  const [enterHistory, setEnterHistory] = createSignal<[string, string]>(['', location.pathname])
  const [exitHistory, setExitHistory] = createSignal<[string, string]>(['', location.pathname])

  const defs = [
    { action: 'forward', from: /^\/$/, to: /^\/rooms\// },
    { action: 'reverse', from: /^\/rooms\//, to: /^\/$/ },
    { action: 'forward', from: /^\/rooms\//, to: /^\/book\/experiences\// },
    { action: 'reverse', from: /^\/book\/experiences\//, to: /^\/rooms\// },
    { action: 'stay.forward', from: /^\/rooms\//, to: /^\/owner$/ },
    { action: 'stay.reverse', from: /^\/owner$/, to: /^\/rooms\// },
    { action: 'stay.forward', from: /^\/book\/experiences\//, to: /^\/legal\// },
    { action: 'stay.reverse', from: /^\/legal\//, to: /^\/book\/experiences\// }
  ]

  const action = (history: Accessor<[string, string]>) => {
    const [from, to] = history()
    for (const route of defs) {
      if (route.from.test(from) && route.to.test(to)) {
        return route.action
      }
    }
    return 'none' // default
  }

  const enter = (element: Element, done: () => void) => {
    if (!hasExitElemet()) return done()
    const target = element.childNodes[0] as HTMLDivElement
    const act = action(enterHistory)
    if (act === 'none') return done()
    const animation = act === 'forward'
      ? keyframe.enter.from.right
      : act === 'reverse'
      ? keyframe.enter.from.left
      : act === 'stay.forward'
      ? keyframe.enter.from.in
      : keyframe.enter.from.out
    const animator = target.animate(animation, options)
    animator.finished.then(done)
  }

  const exit = (element: Element, done: () => void) => {
    if (!hasExitElemet()) return done()
    const act = action(exitHistory)
    if (act === 'none') return done()
    element.classList.add(styled.root)
    if (act === 'forward' || act === 'stay.forward') {
      element.classList.add(transition.direction.forward)
    } else {
      element.classList.add(transition.direction.reverse)
    }
    const target = element.childNodes[0] as HTMLDivElement
    const animation = act === 'forward'
      ? keyframe.exit.to.left
      : act === 'reverse'
      ? keyframe.exit.to.right
      : act === 'stay.forward'
      ? keyframe.exit.to.out
      : keyframe.exit.to.in
    const animator = target.animate(animation, options)
    animator.finished.then(done)
  }

  return (
    <Transition
      onBeforeEnter={() => setEnterHistory([enterHistory()[1], location.pathname])}
      onBeforeExit={() => {
        if (!hasExitElemet()) setHasExitElemet(true)
        setExitHistory([exitHistory()[1], location.pathname])
      }}
      onEnter={(element, done) => enter(element, done)}
      onExit={(element, done) => exit(element, done)}
    >
      {children}
    </Transition>
  )
}
